import { createRouter, createWebHistory } from 'vue-router'

import { publicRoutes } from './publicRoutes'
import { privateRoutes } from './privateRoutes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...publicRoutes, ...privateRoutes]
})

export default router
