import request from '@/utils/request'

// 登录的请求接口
export const login = (data) => {
  return request({
    url: '/admins/login',
    method: 'POST',
    data
  })
}
// 登录的请求接口
export const getCaptcha = (data) => {
  return request({
    url: '/admins/verify-codes/captcha',
    method: 'POST',
    data
  })
}
// 刷新token
export const getRefreshToken = () => {
  return request({
    url: '/admins/refresh-token',
    method: 'POST'
  })
}

// 获取手机验证码
export const getPhoneCode = (data) => {
  return request({
    url: '/admins/verify-codes/phone',
    method: 'POST',
    data
  })
}
