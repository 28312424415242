import router from '@/router'
import store from '@/store'
import storage from './utils/storage'

// 定义一个白名单地址，可以在没有token的时候访问
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  // 先判断是不是访问的白名单地址
  if (whiteList.indexOf(to.path) > -1) {
    if (store.getters.token) {
      next('/')
    } else {
      next()
    }
  } else {
    // 不是白名单地址 进入到这里,判断是否有token
    if (!storage.getItem('token')) {
      next('/login')
    } else {
      next()
    }
  }
})
