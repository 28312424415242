import layout from '@/layout'
export default {
  path: '/admin',
  component: layout,
  name: 'admin',
  redirect: '/admin/list',
  meta: {
    title: '管理员'
  },
  children: [
    {
      path: '/admin/list',
      component: () => import('@/views/admin'),
      name: 'admin-list',
      meta: {
        title: '管理员列表'
      }
    }
  ]
}
