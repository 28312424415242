import layout from '@/layout'

export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/welcome',
    name: '首页',
    meta: {
      title: '首页',
      icon: 'home'
    },
    children: [
      {
        path: '/welcome',
        name: '欢迎页面',
        component: () => import('@/views/welcome/index'),
        meta: {
          title: '欢迎',
          icon: 'home'
        }
      },
      {
        path: '/404',
        name: '404页面',
        component: () => import('@/views/error-page/404')
      }
    ]
  }
]
