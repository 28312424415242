import layout from '@/layout'
export default {
  path: '/train',
  component: layout,
  name: 'train',
  meta: {
    title: '训练',
    icon: 'train'
  },
  children: [
    {
      path: '/book/list',
      component: () => import('@/views/train/book/index'),
      name: 'book',
      meta: {
        title: '专栏',
        icon: 'train'
      }
    },
    {
      path: '/system-course',
      component: () => import('@/views/train/system-course/index'),
      name: 'system-course',
      meta: {
        title: '体系课',
        icon: 'train'
      }
    },
    {
      path: '/system-course/stage/:id',
      component: () => import('@/views/train/system-course/stage.vue'),
      name: 'system-course-stage',
      meta: {
        title: '体系课阶段'
      }
    },
    {
      path: '/system-course/detail/:id',
      component: () => import('@/views/train/system-course/detail.vue'),
      name: 'system-course-detail',
      meta: {
        title: '体系课详情'
      }
    },
    {
      path: '/qustion/list',
      component: () => import('@/views/train/question/index.vue'),
      name: 'question',
      meta: {
        title: '提问管理'
      }
    },
    {
      path: '/answer/list',
      component: () => import('@/views/train/answer/index.vue'),
      name: 'answer',
      meta: {
        title: '回答管理'
      }
    },
    {
      path: '/comment/list',
      component: () => import('@/views/train/comment/index.vue'),
      name: 'comment',
      meta: {
        title: '评论管理'
      }
    }
  ]
}
