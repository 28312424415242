import { ref, h } from 'vue'
import { RouterLink } from 'vue-router'
import { NIcon } from 'naive-ui'
import { HomeOutline as HomeIcon } from '@vicons/ionicons5'
import { UserRegular } from '@vicons/fa'
import {
  AdminPanelSettingsOutlined,
  ModelTrainingSharp,
  GolfCourseFilled,
  PermDataSettingOutlined,
  SupervisedUserCircleOutlined,
  BookOutlined,
  BookmarkAddedOutlined,
  FeaturedVideoOutlined,
  AltRouteFilled,
  SupervisorAccountTwotone,
  AddLinkFilled,
  ArticleRound,
  // ArticleOutlined,
  CommentOutlined,
  QuestionMarkOutlined,
  QuestionAnswerOutlined
} from '@vicons/material'

const renderIcon = (icon) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}
export const menuOptions = ref([
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: '首页'
          }
        },
        { default: () => '首页' }
      ),
    key: 'go-back-home',
    icon: renderIcon(HomeIcon)
  },
  {
    label: '用户',
    key: 'user-admin',
    icon: renderIcon(UserRegular),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'admin'
              }
            },
            { default: () => '管理员' }
          ),
        key: 'admins',
        icon: renderIcon(AdminPanelSettingsOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user'
              }
            },
            { default: () => '普通用户' }
          ),
        key: 'users',
        icon: renderIcon(SupervisedUserCircleOutlined)
      }
    ]
  },
  {
    label: '训练',
    key: 'train',
    icon: renderIcon(ModelTrainingSharp),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'book'
              }
            },
            { default: () => '专栏' }
          ),
        key: 'books',
        icon: renderIcon(BookOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'system-course'
              }
            },
            { default: () => '体系课' }
          ),
        key: 'system-course',
        icon: renderIcon(BookmarkAddedOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'comment'
              }
            },
            { default: () => '评论管理' }
          ),
        key: 'comment',
        icon: renderIcon(CommentOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'question'
              }
            },
            { default: () => '提问管理' }
          ),
        key: 'question',
        icon: renderIcon(QuestionMarkOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'answer'
              }
            },
            { default: () => '回答管理' }
          ),
        key: 'answer',
        icon: renderIcon(QuestionAnswerOutlined)
      }
    ]
  },
  {
    label: '课程',
    key: 'course',
    icon: renderIcon(GolfCourseFilled),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'course-video'
              }
            },
            { default: () => '视频课程' }
          ),
        key: 'course-video',
        icon: renderIcon(FeaturedVideoOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'learn-path'
              }
            },
            { default: () => '学习路线' }
          ),
        key: 'learn-path',
        icon: renderIcon(AltRouteFilled)
      }
    ]
  },
  {
    label: '设置',
    key: 'settings',
    icon: renderIcon(PermDataSettingOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'vip-list'
              }
            },
            { default: () => 'VIP设置' }
          ),
        key: 'vip',
        icon: renderIcon(SupervisorAccountTwotone)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'link'
              }
            },
            { default: () => '友情链接' }
          ),
        key: 'link',
        icon: renderIcon(AddLinkFilled)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'article-setting'
              }
            },
            { default: () => '首页数据' }
          ),
        key: 'article',
        icon: renderIcon(ArticleRound)
      }
    ]
  }
])
