import layout from '@/layout'
export default {
  path: '/book',
  component: layout,
  name: 'train-book',
  meta: {
    title: '专栏'
  },
  children: [
    {
      path: '/book/detail/:id',
      component: () => import('@/views/train/book/bDetail.vue'),
      name: 'book-detail'
    },
    {
      path: '/book/document/edit/:id',
      component: () => import('@/views/train/book/editDocument.vue'),
      name: 'book-document-edit'
    },
    {
      path: '/book/document/detail/:id',
      component: () => import('@/views/train/book/docDetail.vue'),
      name: 'book-document-detail'
    },
    {
      path: '/book/category/list',
      component: () => import('@/views/train/book/category.vue'),
      name: 'book-category'
    }
  ]
}
