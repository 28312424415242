import layout from '@/layout'
export default {
  path: '/vip',
  component: layout,
  name: 'vip',
  meta: {
    title: '设置'
  },
  redirect: '/vip/list',
  children: [
    {
      path: '/vip/list',
      component: () => import('@/views/setting/vip/index'),
      name: 'vip-list'
    }
  ]
}
