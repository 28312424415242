import layout from '@/layout'
export default {
  path: '/courses',
  component: layout,
  name: 'course',
  meta: {
    title: '课程'
  },
  redirect: '/courses/list',
  children: [
    {
      path: '/courses/list',
      component: () => import('@/views/course/index'),
      name: 'course-video',
      meta: {
        title: '视频课程'
      }
    },

    {
      path: '/courses/learnpath',
      component: () => import('@/views/course/learn-path/index'),
      name: 'learn-path',
      meta: {
        title: '学习路线'
      }
    },
    {
      path: '/courses/learnpath/:id',
      component: () => import('@/views/course/learn-path/detail'),
      name: 'learn-path-detail',
      meta: {
        title: '学习路线详情'
      }
    },
    {
      path: '/courses/learnpath/stage/:id',
      component: () => import('@/views/course/learn-path/stage'),
      name: 'learn-path-stage',
      meta: {
        title: '学习路线阶段'
      }
    },
    {
      path: '/courses/category',
      component: () => import('@/views/course/category'),
      name: 'course-category',
      meta: {
        title: '课程分类'
      }
    },
    {
      path: '/courses/detail/:id',
      component: () => import('@/views/course/detail'),
      name: 'course-detail',
      meta: {
        title: '课程详情'
      }
    }
  ]
}
