import { login } from '@/api/login'
import storage from '@/utils/storage'
import { TOKEN } from '@/config'
import router from '@/router'
export default {
  namespaced: true,
  state: () => ({
    token: storage.getItem(TOKEN) || ''
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      storage.setItem(TOKEN, token)
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { phone, verify_code } = userInfo
      return new Promise((resolve, reject) => {
        login({
          phone,
          verify_code
        })
          .then((data) => {
            resolve(data)
            commit('setToken', data.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    logout({ commit }) {
      commit('setToken', '')
      storage.clearAll()
      router.push('/login')
    }
  }
}
