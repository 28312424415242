import layout from '@/layout'
export default {
  path: '/setting',
  component: layout,
  name: 'setting',
  meta: {
    title: '文章'
  },
  children: [
    {
      path: '/article',
      component: () => import('@/views/setting/article'),
      name: 'article-setting',
      meta: {
        title: '设置文章数据'
      }
    }
  ]
}
