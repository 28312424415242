import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_n_message_provider = _resolveComponent("n-message-provider");
  const _component_NLoadingBarProvider = _resolveComponent("NLoadingBarProvider");
  return _openBlock(), _createBlock(_component_NLoadingBarProvider, null, {
    default: _withCtx(() => [_createVNode(_component_n_message_provider, null, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  });
}